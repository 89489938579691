import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { SVG, easing } from '@svgdotjs/svg.js'

import Layout from '../components/layout'
import SEO from '../components/seo'
import useElementSize from '../hooks/useElementSize'
import useHeaderLines from '../hooks/useHeaderLines'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'

interface Data {
  markdownRemark: {
    frontmatter: {
      title: string
    }
    html: string
  }
}

const LegalTerms: React.FC<PageProps<Data>> = ({ data, location }) => {
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()
  const pageTitle = data.markdownRemark.frontmatter.title
  const html = data.markdownRemark.html

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(document.querySelector('.page--legal'))
      const preIntroRect = getAbsoluteOffset(
        document.querySelector('.pre-intro'),
      )

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      svg.polyline([
        ...headerLines,

        pageRect.left,
        headerRect.bottom,

        pageRect.left,
        preIntroRect.top,

        pageRect.left,
        preIntroRect.bottom,

        pageRect.right,
        preIntroRect.bottom,
      ])

      return () => svg.remove()
    }
  }, [size])

  return (
    <Layout>
      <SEO title={pageTitle} />
      <div className="pre-intro" style={{ height: 50 }}></div>
      <main ref={pageRef} className={`container page--legal`}>
        <article dangerouslySetInnerHTML={{ __html: html }} />
      </main>
    </Layout>
  )
}

export default LegalTerms

export const pageQuery = graphql`
  query legalTerms {
    markdownRemark(fileAbsolutePath: { regex: "/(legalterms)/" }) {
      frontmatter {
        title
      }
      html
    }
  }
`
